import { render, staticRenderFns } from "./Overview.vue?vue&type=template&id=4bc3c99a&scoped=true&"
import script from "./Overview.vue?vue&type=script&lang=js&"
export * from "./Overview.vue?vue&type=script&lang=js&"
import style0 from "./Overview.vue?vue&type=style&index=0&id=4bc3c99a&scoped=true&lang=css&"
import style1 from "./Overview.vue?vue&type=style&index=1&id=4bc3c99a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc3c99a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VDataTable,VDialog,VFileInput,VIcon,VProgressLinear,VRow,VSlider,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/-36BVGaF/0/applicationg/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4bc3c99a')) {
      api.createRecord('4bc3c99a', component.options)
    } else {
      api.reload('4bc3c99a', component.options)
    }
    module.hot.accept("./Overview.vue?vue&type=template&id=4bc3c99a&scoped=true&", function () {
      api.rerender('4bc3c99a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Overview.vue"
export default component.exports